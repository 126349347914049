.contenedor_datepicker{
	display: 'flex';
    alignItems: 'center';
}

.contenedor_datepicker, 
.contenedor_datepicker > div.react-datepicker-wrapper, 
.contenedor_datepicker > div > div.react-datepicker__input-container
.contenedor_datepicker > div > div.react-datepicker__input-container input {
   width: 100%;
}